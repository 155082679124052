<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.14611 1.27234C10.3561 1.36092 11.3304 2.02234 11.4647 2.84377C11.5647 3.4552 11.6504 4.08377 11.6504 4.72234C11.6504 5.36234 11.5647 5.98949 11.4647 6.60091C11.3304 7.42377 10.3561 8.08663 9.14611 8.17234C8.23267 8.24655 7.31681 8.28705 6.40039 8.29377C5.4661 8.29377 4.54896 8.23806 3.65468 8.17377C2.44468 8.0852 1.47182 7.42377 1.3361 6.59949C1.22327 5.97938 1.16116 5.35111 1.15039 4.72092C1.15039 4.08234 1.2361 3.45377 1.3361 2.84377C1.47182 2.02092 2.44468 1.35806 3.65468 1.27092C4.56817 1.19806 5.48402 1.15851 6.40039 1.15234C7.3361 1.15234 8.25325 1.20663 9.14611 1.27234Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9116 18.7564C9.70301 18.6693 8.73016 18.005 8.59587 17.1807C8.48315 16.5596 8.42104 15.9304 8.41016 15.2993C8.41016 14.6578 8.49587 14.0293 8.59587 13.4164C8.73016 12.5921 9.70301 11.9293 10.9116 11.8407C11.8246 11.7673 12.74 11.7273 13.6559 11.7207C14.5902 11.7207 15.5073 11.7764 16.4002 11.8407C17.6102 11.9293 18.583 12.5921 18.7173 13.4164C18.8173 14.0307 18.903 14.6593 18.903 15.2993C18.903 15.9407 18.8173 16.5693 18.7173 17.1807C18.583 18.0064 17.6102 18.6693 16.4002 18.7578C15.5073 18.8221 14.5902 18.8778 13.6573 18.8778C12.7409 18.8709 11.8251 18.8294 10.9116 18.7564Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.9994 1.27234C18.4365 1.36092 18.7879 2.02234 18.8365 2.84377C18.8722 3.4552 18.9037 4.08377 18.9037 4.72234C18.9037 5.36234 18.8722 5.98949 18.8365 6.60091C18.7879 7.42377 18.4365 8.08663 17.9994 8.17234C17.6733 8.2457 17.3407 8.28637 17.0065 8.29377C16.6729 8.28649 16.3408 8.24629 16.0151 8.17377C15.5765 8.0852 15.2251 7.42377 15.1765 6.60091C15.1356 5.97553 15.1132 5.34906 15.1094 4.72234C15.1094 4.08377 15.1408 3.4552 15.1765 2.8452C15.2251 2.02234 15.5765 1.35949 16.0151 1.27234C16.3365 1.20663 16.6679 1.15234 17.0065 1.15234C17.3451 1.15234 17.6765 1.20663 17.9994 1.27234Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.05468 18.7564C1.61753 18.6693 1.2661 18.005 1.21753 17.1807C1.17659 16.5544 1.1542 15.9269 1.15039 15.2993C1.15039 14.6578 1.18182 14.0293 1.21753 13.4164C1.2661 12.5921 1.61753 11.9293 2.05468 11.8407C2.3809 11.7683 2.71345 11.7281 3.04753 11.7207C3.3861 11.7207 3.71753 11.7764 4.04039 11.8407C4.47753 11.9293 4.82896 12.5921 4.87753 13.4164C4.91325 14.0307 4.94468 14.6593 4.94468 15.2993C4.94468 15.9407 4.91325 16.5693 4.87753 17.1807C4.82896 18.0064 4.47753 18.6693 4.04039 18.7578C3.71426 18.8308 3.38165 18.871 3.04753 18.8778C2.71345 18.8705 2.3809 18.8288 2.05468 18.7564Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
